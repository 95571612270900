<ion-menu side="end"
  menuId="base-menu"
  contentId="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title>{{res.menu}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content scroll-y="false">
    <div class="menu-wrapper">
      <div class="menu-item"
        (click)="logout()"
        nk-ripple>{{res.logout}}
      </div>
    </div>
  </ion-content>
</ion-menu>

<ion-menu menuId="device-menu"
  side="end"
  contentId="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title>{{res.menu}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content scroll-y="false">
    <div class="menu-wrapper">
      <div class="menu-item"
        (click)="logout()"
        nk-ripple>{{res.logout}}
      </div>
      <div class="menu-item"
        (click)="resetDevice()"
        nk-ripple>{{res.undoSensorChanges}}
      </div>
    </div>
  </ion-content>
</ion-menu>

<ion-menu menuId="area-menu"
  side="end"
  contentId="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title>{{res.menu}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content scroll-y="false">
    <div class="menu-wrapper">
      <div class="menu-item"
        (click)="logout()"
        nk-ripple>{{res.logout}}
      </div>
      <div class="menu-item"
        (click)="resetArea()"
        nk-ripple>{{res.undoAreaChanges}}
      </div>
    </div>
  </ion-content>
</ion-menu>

<ion-menu menuId="floor-menu"
  side="end"
  contentId="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title>{{res.menu}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content scroll-y="false">
    <div class="menu-wrapper">
      <div class="menu-item"
        (click)="logout()"
        nk-ripple>{{res.logout}}
      </div>
      <div class="menu-item"
        (click)="resetFloor()"
        nk-ripple>{{res.undoFloorChanges}}
      </div>
    </div>
  </ion-content>
</ion-menu>