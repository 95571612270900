import { MenuController } from '@ionic/angular/standalone';
import {
    EventEmitter,
    Injectable
} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class MenuService {

    public readonly onDeviceReset: EventEmitter<void> = new EventEmitter<void>();
    public readonly onAreaReset: EventEmitter<void> = new EventEmitter<void>();
    public readonly onFloorReset: EventEmitter<void> = new EventEmitter<void>();

    constructor(public menu: MenuController) { }

    public resetDevice(): void {
        this.onDeviceReset.emit();
    }

    public resetArea(): void {
        this.onAreaReset.emit();
    }

    public resetFloor(): void {
        this.onFloorReset.emit();
    }

    public close(): void {
        this.menu.close();
    }

}