import { Component } from '@angular/core';
import { AuthenticationProvider } from '@comMobileApp/core/auth';
import { GlobalRes } from '@comMobileApp/core/i18n';
import {
  IonApp,
  IonRouterOutlet,
  IonMenu,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonButton
} from '@ionic/angular/standalone';
import { NkRippleModule } from '@nkControls';
import { MenuService } from './menu.service';

@Component({
  selector: 'menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss'],
  imports: [
    IonApp,
    IonRouterOutlet,
    IonMenu,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonButton,
    NkRippleModule,
  ],
  standalone: true
})
export class MenuComponent {

  constructor(
    private menuService: MenuService,
    private authenticationProvider: AuthenticationProvider,
    public res: GlobalRes
  ) { }

  public logout(): void {
    this.authenticationProvider.logout();
  }

  public resetDevice(): void {
    this.menuService.resetDevice();
  }

  public resetArea(): void {
    this.menuService.resetArea();
  }

  public resetFloor(): void {
    this.menuService.resetFloor();
  }

}
